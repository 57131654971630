import { defineNuxtPlugin } from '#app/nuxt'
import { LazyFlexibleAccordion, LazyFlexibleBlockquote, LazyFlexibleCalltoaction, LazyFlexibleEllipses, LazyFlexibleEmailcolumns, LazyFlexibleHerotext, LazyFlexibleIconlist, LazyFlexibleImages, LazyFlexibleImagetext, LazyFlexibleParallaximages, LazyFlexiblePeople, LazyFlexibleRelatedcases, LazyFlexibleServices, LazyFlexibleSinglevideo, LazyFlexibleStickytitletext, LazyFlexibleTextcolumns, LazyFlexibleVideos, LazyNuxtIcon, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["FlexibleAccordion", LazyFlexibleAccordion],
["FlexibleBlockquote", LazyFlexibleBlockquote],
["FlexibleCalltoaction", LazyFlexibleCalltoaction],
["FlexibleEllipses", LazyFlexibleEllipses],
["FlexibleEmailcolumns", LazyFlexibleEmailcolumns],
["FlexibleHerotext", LazyFlexibleHerotext],
["FlexibleIconlist", LazyFlexibleIconlist],
["FlexibleImages", LazyFlexibleImages],
["FlexibleImagetext", LazyFlexibleImagetext],
["FlexibleParallaximages", LazyFlexibleParallaximages],
["FlexiblePeople", LazyFlexiblePeople],
["FlexibleRelatedcases", LazyFlexibleRelatedcases],
["FlexibleServices", LazyFlexibleServices],
["FlexibleSinglevideo", LazyFlexibleSinglevideo],
["FlexibleStickytitletext", LazyFlexibleStickytitletext],
["FlexibleTextcolumns", LazyFlexibleTextcolumns],
["FlexibleVideos", LazyFlexibleVideos],
["NuxtIcon", LazyNuxtIcon],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
