

import { useMetaStore } from '~/stores/metaStore'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

process.client && gsap.registerPlugin(ScrollTrigger)

export default defineNuxtPlugin((nuxtApp) => {
	const metaStore = useMetaStore()
	const config = useRuntimeConfig()

	nuxtApp.hook('page:start', () => {
		if (process.client) {
			metaStore.pageLoading = true
			nuxtApp.$bus.$emit('page-start')

			if (metaStore.firstLoad) {
				metaStore.$patch({ firstLoad: false })
			}
		}
	})

	nuxtApp.hook('page:finish', () => {
		metaStore.pageLoading = false

		if (process.client && metaStore.firstLoad) {
			metaStore.firstLoad = false
		}

		nuxtApp.$bus.$emit('page-finish')
	})

	nuxtApp.hook('page:transition:finish', () => {
		if (process.client && config.public.environment === 'development') {
			console.log('DefaultLayout: finished navigating - scrolltriggers alive: ', ScrollTrigger.getAll())
		}
	})
})
