import type { RouterOptions } from '@nuxt/schema'

export default <RouterOptions>{
	scrollBehavior(to, from, savedPosition) {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				if (savedPosition && to.name !== 'index') {
					resolve(savedPosition)
				} else {
					if (to.hash) {
						resolve({
							el: to.hash,
							top: 0
						})
					} else {
						resolve({ top: 0 })
					}
				}
			}, 100)
		})
	}
}