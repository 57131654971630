<template>
	<NuxtLayout>
		<div v-if="error.statusCode === 404">
			<client-only>
				<div class="outer-page"
					 :style="{ paddingTop: (metaStore.headerHeight / 2 + 'px ') }">
					<div class="inner-page container-fluid">
						<div class="row">
							<div class="col-xs-12">
								<h5>404!</h5>
								<h6>Page not found</h6>
								<p>Sorry, the page you are looking for does not exist. Try going back to the front page.</p>
								<br>
								<br>
								<button class="button"
										@click.prevent="handleError">
									Back to homepage
								</button>
							</div>
						</div>
					</div>
				</div>

			</client-only>
		</div>
		<div v-else>
			<client-only>
				<div class="outer-page">
					<div class="inner-page container-fluid">
						<div class="row">
							<div class="col-xs-12">
								<h5>{{ error.statusCode }}</h5>
								<h6>{{ error.statusMessage }}</h6>
								<p>{{ error.message }}</p>
								<br>
								<div v-html="error.stack" />
								<br>
								<br>
								<a href="#"
								   class="button"
								   @click="handleError">
									Back to homepage
								</a>
							</div>
						</div>
					</div>
				</div>
			</client-only>
		</div>
	</NuxtLayout>
</template>

<script setup>
import { useMetaStore } from './stores/metaStore'

const props = defineProps({
	error: {
		type: Object,
		default: () => ({})
	}
})

const metaStore = useMetaStore()
let url

const handleError = () => {
	navigateTo(`${url.protocol}//${url.host}`, { external: true })
}

onMounted(() => {
	url = useRequestURL()
	metaStore.error = props.error
	useAnims(() => metaStore.cursorDisabled = true)
})

onBeforeUnmount(() => {
	metaStore.error = null
})
</script>

<style lang="scss" scoped>
.inner-page {
	min-height: 80vh;
	background-color: $white;
}

.button {
	@include standard-link;
}
</style>