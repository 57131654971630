import revive_payload_client_4sVQNw7RlN from "/home/superodd/sites/superodd.com.au/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/superodd/sites/superodd.com.au/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/superodd/sites/superodd.com.au/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/superodd/sites/superodd.com.au/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/superodd/sites/superodd.com.au/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/superodd/sites/superodd.com.au/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/superodd/sites/superodd.com.au/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/superodd/sites/superodd.com.au/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/home/superodd/sites/superodd.com.au/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import hooks_client_5sZD5G2zib from "/home/superodd/sites/superodd.com.au/plugins/hooks.client.js";
import interpolation_fiFMtO1bkE from "/home/superodd/sites/superodd.com.au/plugins/interpolation.js";
import useEmitter_bNfgYX8ThQ from "/home/superodd/sites/superodd.com.au/plugins/useEmitter.js";
import vue_gtm_client_Cm24Dsc5BN from "/home/superodd/sites/superodd.com.au/plugins/vue-gtm.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  hooks_client_5sZD5G2zib,
  interpolation_fiFMtO1bkE,
  useEmitter_bNfgYX8ThQ,
  vue_gtm_client_Cm24Dsc5BN
]