import { defineStore } from 'pinia'

export const useMetaStore = defineStore({
	id: 'meta-store',
	state: () => ({
		error: null,
		firstLoad: true,
		smoother: null,
		loaderActive: false,
		menuActive: false,
		headerHeight: 455,
		transitionDuration: 500,
		cursorActivated: false,
		cursorDisabled: false,
		scrollDisabled: false,
		activePageColors: {
			backgroundColor: null,
			foregroundColor: null,
			detailsColor: null,
			cursorBackgroundColor: null,
			cursorForegroundColor: null,
		}
	}),
	actions: {
		setActivePageColors(payload) {
			//eslint-disable-next-line 
			const { $bus } = useNuxtApp()
			const colors = {
				...({ backgroundColor: payload && payload.backgroundColor }),
				...({ foregroundColor: payload && payload.foregroundColor }),
				...({ detailsColor: payload && payload.detailsColor }),
				...({ cursorBackgroundColor: payload && payload.cursorBackgroundColor }),
				...({ cursorForegroundColor: payload && payload.cursorForegroundColor }),
			}

			if (Object.keys(colors).length) {
				Object.keys(colors).forEach(key => { if (colors[key] && !colors[key].includes('#') && colors[key] != 'transparent') { colors[key] = '#' + colors[key] } })
			}

			this.$patch({ activePageColors: colors })
			$bus.$emit('active-page-colors-changed')

			return this.activePageColors
		}
	},
	getters: {
		getDelay: (state) => {
			let duration = state.firstLoad ? state.transitionDuration : (state.transitionDuration)
			return duration
		}
	}
})