let links = []
let _router = null

const navigate = (event) => {
	const href = event.currentTarget.getAttribute('href')
	const target = event.currentTarget.getAttribute('target') ?? '_self'
	if (href && href[0] === '/') {
		event.preventDefault()
		event.metaKey ? window.open(href, target) : _router.push(href)
	}
}

const removeListeners = (links2) => {
	for (let i = 0; i < links2.length; i++) {
		links2[i].removeEventListener('click', navigate, false)
	}
	links2 = []
}

const addListeners = (links2) => {
	for (let i = 0; i < links2.length; i++) {
		const target = links2[i].getAttribute('target')
		if (target && target === '_blank') {
			const rel = links2[i].getAttribute('rel') || ''
			const attributes = rel.includes('noopener') ? rel : rel + ' noopener'
			links2[i].setAttribute('rel', attributes)
		}
		links2[i].addEventListener('click', navigate, false)
	}
}

export default defineNuxtPlugin((nuxtApp) => {
	_router = nuxtApp.$router
	nuxtApp.vueApp.directive('interpolation', {
		updated() {
			removeListeners(links)
			addListeners(links)
		},
		mounted(el) {
			links = el.getElementsByTagName('a')
			addListeners(links)
		},
		beforeUnmount(el) {
			el.removeEventListener('click', removeListeners(links))
		}
	})
})
