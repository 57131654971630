export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"msapplication-TileColor","content":"#da532c"},{"name":"theme-color","content":"#121212"}],"link":[],"style":[],"script":[{"src":"/js/calcom.js","type":"text/javascript","nonce":"nonce-8bmLAt4Wi/Bc8uo11wWggA==","async":true}],"noscript":[{"children":"Javascript is required"}],"htmlAttrs":{"lang":"en"},"titleTemplate":"Superodd — %s"}

export const appPageTransition = {"name":"so-circle","mode":"out-in"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false