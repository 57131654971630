import { default as _91pageslug_936TxRJCEw7YMeta } from "/home/superodd/sites/superodd.com.au/pages/[pageslug].vue?macro=true";
import { default as index9xCL7Pb0FUMeta } from "/home/superodd/sites/superodd.com.au/pages/index.vue?macro=true";
import { default as _91workslug_93JUxaeQtalmMeta } from "/home/superodd/sites/superodd.com.au/pages/work/[workslug].vue?macro=true";
import { default as indexyXDuZ8ixo8Meta } from "/home/superodd/sites/superodd.com.au/pages/work/index.vue?macro=true";
export default [
  {
    name: _91pageslug_936TxRJCEw7YMeta?.name ?? "pageslug",
    path: _91pageslug_936TxRJCEw7YMeta?.path ?? "/:pageslug()",
    meta: _91pageslug_936TxRJCEw7YMeta || {},
    alias: _91pageslug_936TxRJCEw7YMeta?.alias || [],
    redirect: _91pageslug_936TxRJCEw7YMeta?.redirect,
    component: () => import("/home/superodd/sites/superodd.com.au/pages/[pageslug].vue").then(m => m.default || m)
  },
  {
    name: index9xCL7Pb0FUMeta?.name ?? "index",
    path: index9xCL7Pb0FUMeta?.path ?? "/",
    meta: index9xCL7Pb0FUMeta || {},
    alias: index9xCL7Pb0FUMeta?.alias || [],
    redirect: index9xCL7Pb0FUMeta?.redirect,
    component: () => import("/home/superodd/sites/superodd.com.au/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91workslug_93JUxaeQtalmMeta?.name ?? "work-workslug",
    path: _91workslug_93JUxaeQtalmMeta?.path ?? "/work/:workslug()",
    meta: _91workslug_93JUxaeQtalmMeta || {},
    alias: _91workslug_93JUxaeQtalmMeta?.alias || [],
    redirect: _91workslug_93JUxaeQtalmMeta?.redirect,
    component: () => import("/home/superodd/sites/superodd.com.au/pages/work/[workslug].vue").then(m => m.default || m)
  },
  {
    name: indexyXDuZ8ixo8Meta?.name ?? "work",
    path: indexyXDuZ8ixo8Meta?.path ?? "/work",
    meta: indexyXDuZ8ixo8Meta || {},
    alias: indexyXDuZ8ixo8Meta?.alias || [],
    redirect: indexyXDuZ8ixo8Meta?.redirect,
    component: () => import("/home/superodd/sites/superodd.com.au/pages/work/index.vue").then(m => m.default || m)
  }
]