import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useMetaStore } from '~~/stores/metaStore'
import { delay } from '~/utils'
if (process.client) gsap.registerPlugin(ScrollTrigger)

const triggers = {}
let $q

export function useAnims(callback, triggerObject = null) {
	const metaStore = useMetaStore()

	nextTick(() => {
		nextTick(() => {
			delay(metaStore.getDelay).then(() => callback())
		})
	})

	triggerObject && listenForKill(triggerObject)
}

function listenForKill(triggerObject) {
	onBeforeUnmount(async () => {
		await killTriggers(triggerObject)
	})
}

export function killTriggers(triggerObject) {
	Object.keys(triggerObject).forEach((key) => {
		if (typeof triggerObject[key] === 'object') {
			if (Array.isArray(triggerObject[key])) {
				triggerObject[key].forEach(item => {
					// console.log('killing', item)
					item.kill()
				})

			} else {
				// console.log('killing', triggerObject[key])
				triggerObject[key].kill()
			}

			delete triggerObject[key]
		}

		return true
	})
}

// Functions used to trigger animations 

export function useCssAnims(componentRootEl) {
	const metaStore = useMetaStore()

	delay(metaStore.getDelay).then(() => {
		nextTick(() => {
			nextTick(() => {
				$q = gsap.utils.selector(componentRootEl)

				animTitleExpand()
				staggerReveal()
				parallaxImg()

				ScrollTrigger.refresh(true)
			})
		})
	})

	if (triggers) listenForKill(triggers)
}

function animTitleExpand() {
	const titles = $q('.so-expand')

	titles.forEach((title, i) => {
		triggers['titleExpand' + i] = gsap.timeline({
			scrollTrigger: {
				id: 'titleExpand-' + i,
				trigger: title,
				start: 'top 90%',
			},
		}).to(title, {
			'--font-width': 400,
			duration: 3,
			ease: 'expo.out'
		})
	})
}

function staggerReveal() {
	const elements = $q('.so-reveal')

	elements.forEach((el, i) => {
		triggers['staggerReveal' + i] = gsap
			.timeline({
				scrollTrigger: {
					id: `staggerReveal-${i}`,
					trigger: el,
					start: 'top 90%',
				}
			})
			.from(el, {
				autoAlpha: 0,
				y: 50,
				duration: 1.5,
				stagger: 0.15,
				// delay: () => (i * 0.12),
				ease: 'expo.out'
			})
	})
}

function parallaxImg() {
	// refactor this to look for so-move-speed attributes 

	const containers = $q('.so-paraimg')

	containers.forEach((container, i) => {
		const containerHeight = container.getBoundingClientRect().height
		const img = container.querySelector('img')
		const imgHeight = img.getBoundingClientRect().height
		const toMove = imgHeight - containerHeight

		triggers['parallaxImg-' + i] = gsap.timeline({
			scrollTrigger: {
				id: 'parallaxImg-' + i,
				trigger: img,
				start: 'top 90%',
				end: 'bottom top',
				scrub: true,
			},
		})
			.fromTo(img, {
				y: 0,
			}, {
				y: toMove * -1,
			})
	})
}
